<template>
  <div class="cpt-MMC_Gimbal_Z33N" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机Z33N</div>
      </div>
      <div class="close" @click="$emit('close')">{{$t('operation.Close')}}</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                class="speed"
                :style="control_speed == index + 1 ? 'background: #FFFFFF;color: #333333;' : ''"
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
            <!-- <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.ptz_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦</div>
          <div class="input-box">
            <div
              class="mr10"
              style="cursor: pointer"
              @mousedown="handle_zoom(-1)"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <!-- <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button> -->
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              @change="stopChange"
              @input="onChangezoom"
            />
            <div
              class="ml10"
              style="cursor: pointer"
              @mousedown="handle_zoom(1)"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <!-- <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <el-input
              v-model.number="pitch"
              size="mini"
              placeholder="请输入俯仰"
            />
            <el-button @click="handle_change_pitch" size="mini" type="primary"
              >设置</el-button
            >
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input
              v-model.number="yaw"
              size="mini"
              placeholder="请输入俯仰"
            />
            <el-button @click="handle_change_yaw" size="mini" type="primary"
              >设置</el-button
            >
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">相机模式</div>
          <div class="input-box">
            <el-radio-group
              @change="handle_change_camera_mode"
              v-model="camera_mode"
            >
              <el-radio
                size="mini"
                v-for="item in drd.camera_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div> -->
      </div>
      <div class="rocker-wrap">
        <div class="item-group">
          <div class="item-box" @click="handle_take_photo">
            <div class="icon-box">
              <img src="~@/assets/images/mount/photo.png" alt="" />
              <!-- <span class="iconfont icon-paizhao1"></span> -->
            </div>
          </div>
          <div class="item-box" @click="handle_record">
            <div class="icon-box">
              <img src="~@/assets/images/mount/record.png" alt="" />
              <!-- <span class="iconfont icon-dandianshipin"></span> -->
            </div>
          </div>
        </div>
        <div ref="rocker" class="rocker">
          <div class="shangUp" @click="fangxiang('up')" />
          <div class="xaiUp" @click="fangxiang('down')" />
          <div class="zuoUp" @click="fangxiang('right')" />
          <div class="youUp" @click="fangxiang('left')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_Z33N } from '../utils';
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      holderModelDomV2: 1,
      radio: 1,
      value: 25,
      value2: 25,
      control_speed: 3,
      ptz_mode: 0,
      zoom: 1,
      pitch: 0,
      yaw: 0,
      camera_mode: 0,
      record: false,

      drd: {
        ptz_mode: [
          { id: 0, label: '锁定' },
          { id: 1, label: '跟随' },
          { id: 2, label: '回中' }
        ],
        camera_mode: [
          { id: 0, label: '录像' },
          { id: 2, label: '拍照' }
        ]
      },
      timer: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  methods: {
    onChangezoom(e) {
      if (this.value2 < this.value) {
        this.handle_zoom(1);
      } else if (this.value2 > this.value) {
        this.handle_zoom(-1);
      }
      this.value2 = e;
    },
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      // this.handle_zoom(this)
      const buffer = MMC_Gimbal_Z33N.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value;
      const buffer = MMC_Gimbal_Z33N.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_zoom(value) {
      const buffer = MMC_Gimbal_Z33N.zoom(this.zoom + value);
      this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_Z33N.zoom((this.zoom = 1));
      //   this.commit_directive(buffer);
    },
    handle_change_pitch() {
      const buffer = MMC_Gimbal_Z33N.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      const buffer = MMC_Gimbal_Z33N.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    handle_change_camera_mode(value) {
      const buffer = MMC_Gimbal_Z33N.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    handle_take_photo() {
      const { position } = this;
      const buffer = MMC_Gimbal_Z33N.take_photo(position || {});
      this.commit_directive(buffer);
    },
    handle_record() {
      this.record = !this.record;
      const buffer = MMC_Gimbal_Z33N.record(this.record);
      this.commit_directive(buffer);
    },
    commit_directive(buffer) {
      this.$emit('directive', buffer);
      console.log(buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case 'up':
          buffer = MMC_Gimbal_Z33N.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case 'right':
          buffer = MMC_Gimbal_Z33N.gimbal_yaw_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case 'left':
          buffer = MMC_Gimbal_Z33N.gimbal_yaw_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case 'down':
          buffer = MMC_Gimbal_Z33N.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        buffer = MMC_Gimbal_Z33N.gimbal_pitch_ctrl(0, this.control_speed);
        this.commit_directive(buffer);
        buffer = MMC_Gimbal_Z33N.gimbal_yaw_ctrl(0, this.control_speed);
        this.commit_directive(buffer);
      }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case 'up':
              buffer = MMC_Gimbal_Z33N.gimbal_pitch_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case 'right':
              buffer = MMC_Gimbal_Z33N.gimbal_yaw_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case 'left':
              buffer = MMC_Gimbal_Z33N.gimbal_yaw_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case 'down':
              buffer = MMC_Gimbal_Z33N.gimbal_pitch_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_Z33N.gimbal_pitch_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
          buffer = MMC_Gimbal_Z33N.gimbal_yaw_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_Z33N {
  position: absolute;
  /* bottom: 0;
  left: 0; */
  /* background: center url("~@/assets/images/observe/fckernel/mount/01_bg.png")
    no-repeat; */
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35), inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  width: 500px;
  height: 290px;
  box-sizing: border-box;
  // padding: 10px 20px;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;

    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }
  .bd {
    display: flex;
    justify-content: space-between;
    .form-wrap {
      flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          width: 75px;
        }
        .input-box {
          display: flex;
          align-items: center;
          .mono {
            display: flex;
            flex-direction: column;
            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url('~@/assets/images/mount_long.png') no-repeat;
              background-size: 100%;
              margin-bottom: 5px;
              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }
              .active {
                background: url('~@/assets/images/mount/mount_control.png') no-repeat !important;
                background-size: 100% 100%;
              }
            }
            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }
    .rocker-wrap {
      flex: 1;
      .item-group {
        display: flex;
        justify-content: space-evenly;
        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #08c2d1;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }
      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url('~@/assets/images/accident/caozuo.png') no-repeat;
        background-size: 100% 100%;
        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #004fff;
    color: #000;
    border: none;
  }
}
::v-deep .el-slider__bar {
  background: #fff;
}
.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #43deff;
  border-radius: 12px;
  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}
.iconfont {
  color: #004fff;
}
// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}
</style>
